import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import classes from './CompanyDetailsNew.module.scss';
import commonClasses from '../Wrapper/Wrapper.module.scss';

import AddFieldModal from 'components/CreateProfile/AddFieldModal/AddFieldModal';
import {useNavigate} from 'react-router-dom';
import {adjustColorBrightness, createGradient, PRIMARY_COLOR} from 'common/constants';
import * as actions from '../../../../../../store/actions';
import {getCompanyDetails, updateCompanyDetails} from '../../../../../../store/actions';

import {base64ToFile, getPreSignedTempUrl, showToast, validateFields} from 'common/utils';
import {MdOutlineClose} from 'react-icons/md';
import {Spin} from 'antd';
import {COMPANY_DATA_FIELDS, DATA_FIELD_TYPE, PROFILE_DATA_PRIVACY_MODE, PROFILE_FIELD_LAYOUT_TYPE} from 'common/enums';
import {useDropzone} from 'react-dropzone';
import {IoMdCloudUpload} from 'react-icons/io';
import imageCompression from 'browser-image-compression';
import {IoImageOutline} from 'react-icons/io5';
import ImageCropper from 'components/CreateProfile/ImageCropper/ImageCropper';
import ChangeLayoutModal from 'components/CreateProfile/ChangeLayoutModal/ChangeLayoutModal';
import {renderField} from "../../../../../CreateProfile/CreateOrEditProfile";

const CompanyDetailsNew = props => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [adjustColor, setAdjustColor] = useState(null);
    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
    const [showCropper, setShowCropper] = useState(false);
    const [showChangeLayoutModal, setShowChangeLayoutModal] = useState(false);

    const [compressedImage, setCompressedImage] = useState(null);
    const [logoPreview, setLogoPreview] = useState(undefined);
    const [logo, setLogo] = useState(undefined);

    const leftCompanyFields = [COMPANY_DATA_FIELDS.NAME.name, COMPANY_DATA_FIELDS.ADDRESS.name, COMPANY_DATA_FIELDS.CONTACT_NUMBER.name, COMPANY_DATA_FIELDS.WEBSITE.name, COMPANY_DATA_FIELDS.EMAIL.name,];

    const compressImage = async file => {
        if (file.type.split('/')[0] !== 'image') {
            showToast('Please select a valid image to compress', props.themeColor);
            return;
        }

        try {
            const compressedImage = await imageCompression(file, {
                maxSizeMB: 0.1, maxWidthOrHeight: 1280, useWebWorker: true,
            });

            const base64Result = await getBase64(compressedImage);
            setCompressedImage(base64Result);
            setShowCropper(true);
        } catch (error) {
            console.error('Image compression error: ', props.themeColor);
            showToast('Error compressing image', props.themeColor);
        }
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        compressImage(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const [companyFields, setCompanyFields] = useState(Object.entries(COMPANY_DATA_FIELDS).map(([key, {
        name,
        icon,
        type,
        privacyMode,
        privacyModeToggleAllowed,
        layout
    },]) => ({
        id: null,
        name: name || key,
        icon: icon || null,
        type: type || DATA_FIELD_TYPE.TEXT,
        privacyMode: privacyMode || PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: privacyModeToggleAllowed || false,
        value: '',
        layout: layout || PROFILE_FIELD_LAYOUT_TYPE.LIST,
    }),),);

    useEffect(() => {
        setAdjustColor(adjustColorBrightness(props.themeColor, 60));
    }, []);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            if (!props.companyDetails) {
                try {
                    setLoadingMessage('Loading');
                    setIsLoading(true);
                    const companyData = await props.onGetCompanyDetails();
                    await processCompanyData(companyData);
                } catch (error) {
                    console.error('Failed to load company data:', error);
                    showToast('Failed to load company data', props.themeColor);
                    setCompanyFields([]);
                } finally {
                    setIsLoading(false);
                }
            } else {
                await processCompanyData(props.companyDetails);
            }
        };

        const processCompanyData = async (companyData) => {

            if (!Array.isArray(companyData)) {
                console.error('Unexpected data format:', companyData);
                setCompanyFields([]);
                return;
            }

            const {logoField, filteredFields} = companyData.reduce((acc, field) => {
                if (field.name === 'Logo') {
                    acc.logoField = field;
                } else {
                    acc.filteredFields.push(field);
                }
                return acc;
            }, {logoField: null, filteredFields: []},);

            setCompanyFields(filteredFields);

            if (logoField) {
                if (logoField.value) {
                    let logoUrl = logoField.value;
                    if (!logoUrl.includes('public')) {
                        logoUrl = await getPreSignedTempUrl(logoUrl);
                    }
                    setLogoPreview(logoUrl);
                }
            }
        };

        fetchCompanyDetails();
    }, [props.companyDetails]);

    const onUpdateProfileField = updatedField => {
        setCompanyFields(prevFields => prevFields.map(field => field.name === updatedField.name ? {...field, ...updatedField} : field,),);
    };

    const onUpdateProfileFields = updatedFields => {
        setCompanyFields(prevFields => prevFields.map(field => {
            const updatedField = updatedFields.find(f => f.name === field.name);
            return updatedField ? {...field, ...updatedField} : field;
        }),);
    };

    const onAddProfileField = data => {
        const updatedCompanyFields = [...companyFields];
        updatedCompanyFields.push();

        setCompanyFields([...companyFields, {
            id: null,
            name: data.fieldName,
            icon: data.fieldIcon,
            type: data.fieldType,
            value: '',
            privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
            privacyModeToggleAllowed: true,
            isCustom: true,
        },]);
    };

    const REQUIRED_FIELDS = [COMPANY_DATA_FIELDS.NAME.name, COMPANY_DATA_FIELDS.EMAIL.name, COMPANY_DATA_FIELDS.CONTACT_NUMBER.name,];

    const onRemoveProfileField = fieldName => {
        setCompanyFields(companyFields.filter(field => field.name !== fieldName));
    };

    const renderCompanyLogoForDesktop = () => (<div
        className={classes.DropArea}
        {...(!isReadOnly ? getRootProps() : [])}
        style={{
            borderColor: isDragActive ? PRIMARY_COLOR : '#424242', cursor: isReadOnly ? 'no-drop' : 'pointer',
        }}
    >
        {' '}
        {!isReadOnly && <input {...getInputProps()} accept="image/*"/>}
        <div>
            <div className={classes.ImageContainer}>
                {logoPreview && !isReadOnly && (<div
                    className={classes.ClearButton}
                    onClick={e => {
                        setCompressedImage(null);
                        setLogo(null);
                        setLogoPreview(null);
                        e.stopPropagation();
                    }}
                >
                    <MdOutlineClose size={18} color="#C5C5C5"/>
                </div>)}
                {logoPreview ? (
                    <img src={logoPreview} alt="Company Logo" width={100} style={{objectFit: 'cover'}}/>) : (
                    <div className={classes.IconContainer}>
                        <IoMdCloudUpload size={72} color="#919191"/>
                    </div>)}
            </div>

            {!isReadOnly && (<div style={{marginLeft: '32px', maxWidth: '240px'}}>
                <p>
                    Drag & drop or click to select
                    your Company Logo
                </p>
            </div>)}
        </div>
    </div>);

    const renderCompanyLogoForMobile = () => (<div className={classes.MobileProfilePictureContainer}>
        <div
            style={{
                background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(adjustColor, 50,)} 100%)`,
            }}
            className={classes.ProfilePicture}
            {...getRootProps()}
        >
            <div>
                {logoPreview && !isReadOnly && (<div
                    className={classes.ClearButton}
                    onClick={e => {
                        setCompressedImage(null);
                        setLogo(null);
                        setLogoPreview(null);
                        e.stopPropagation();
                    }}
                >
                    <MdOutlineClose size={16} color="#C5C5C5"/>
                </div>)}
                {logoPreview ? (<img src={logoPreview} alt="Company Logo" style={{objectFit: 'cover'}}/>) : (
                    <IoImageOutline size={32} color="#929292"/>)}
            </div>
        </div>
        <p>Company Logo</p>
    </div>);

    const uploadProfilePicture = async () => {
        let file;
        if (logo.includes(';base64')) {
            file = base64ToFile(logo, 'company-logo');
        } else {
            file = logo;
        }

        try {
            setLoadingMessage('Uploading Company Logo');
            setIsLoading(true);
            const uploadResult = await props.onUploadFile(file);
            return uploadResult.data;
        } catch (error) {
            showToast('Failed to upload company logo', props.themeColor);
            console.log('error', error);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const onSaveHandler = async () => {
        setSaveButtonIsClicked(true);
        const errors = validateFields(companyFields, REQUIRED_FIELDS);

        if (errors.length > 0) {
            errors.forEach(error => showToast(error, props.themeColor));
            setSaveButtonIsClicked(false); // Reset the save button state
            return;
        }
        let logoUploadResult = null;
        if (logo) {
            logoUploadResult = await uploadProfilePicture();

            if (logoUploadResult === null) {
                setSaveButtonIsClicked(false); // Reset the save button state
                return;
            }
        }

        const filteredData = companyFields.filter(field => !['Logo', COMPANY_DATA_FIELDS.NAME.name, COMPANY_DATA_FIELDS.ADDRESS.name, COMPANY_DATA_FIELDS.CONTACT_NUMBER.name, COMPANY_DATA_FIELDS.EMAIL.name, COMPANY_DATA_FIELDS.WEBSITE.name].includes(field.name));

        // Extract field values from profileFields based on matching names
        const getFieldValue = (fieldName) => companyFields.find(field => field.name === fieldName)?.value || null;

        const requestData = {
            logo: logoUploadResult ? logoUploadResult : logoPreview,
            name: getFieldValue(COMPANY_DATA_FIELDS.NAME.name),
            address: getFieldValue(COMPANY_DATA_FIELDS.ADDRESS.name),
            contactNo: getFieldValue(COMPANY_DATA_FIELDS.CONTACT_NUMBER.name),
            email: getFieldValue(COMPANY_DATA_FIELDS.EMAIL.name),
            website: getFieldValue(COMPANY_DATA_FIELDS.WEBSITE.name),
            data: filteredData,
        };

        try {
            setLoadingMessage('Saving company details');
            setIsLoading(true);

            await props.onUpdateCompanyDetails(requestData,);
            showToast('Company details updated successfully', props.themeColor);

        } catch (error) {
            showToast('Failed to save company details', props.themeColor);
            console.log(error);
        } finally {
            setIsLoading(false);
            setSaveButtonIsClicked(false);
        }
    };

    return (<div className={classes.CreateProfile}>
        {showCropper && (<ImageCropper
            image={compressedImage}
            onChangeImage={image => {
                setCompressedImage(null);
                setLogo(image);
                setLogoPreview(image);
            }}
            onClose={() => setShowCropper(false)}
            themeColor={props.themeColor}
        />)}
        {(props.loading || isLoading) && (<Spin
            tip={loadingMessage}
            size={'large'}
            style={{color: props.themeColor}}
            fullscreen
        />)}
        {/*<div className={classes.Header}></div>*/}

        <div className={commonClasses.Wrapper}>
            <div
                className={commonClasses.TopContainer}
                style={{justifyContent: 'center'}}
            >
                <div className={commonClasses.Title}>
                    <h1>Company Details</h1>
                </div>
            </div>
            <div>
                <div className={classes.CreateProfileFormContainer}>
                    {/* Company logo rendering */}
                    {renderCompanyLogoForDesktop()}
                    {renderCompanyLogoForMobile()}
                </div>
                <div className={classes.InputContainer}>
                    {/* Left column */}
                    <div>
                        {companyFields
                            .filter(field => leftCompanyFields.includes(field.name))
                            .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}
                    </div>
                    {/* Right column */}
                    <div>
                        {companyFields
                            .filter(field => !leftCompanyFields.includes(field.name))
                            .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}

                        {!isReadOnly && (<div
                            className={classes.AddFieldButton}
                            onClick={() => setShowAddFieldModal(true)}
                        >
                            <p>+ Add Field</p>
                        </div>)}
                        <br/>
                        {!isReadOnly && (
                            <>
                                <button
                                    className={classes.ChangeLayoutButtonMobile}
                                    disabled={saveButtonIsClicked}
                                    onClick={() => setShowChangeLayoutModal(true)}
                                >
                                    Change Layout
                                </button>
                                <button
                                    style={{
                                        background: `linear-gradient(91.3deg, ${
                                            props.themeColor
                                        } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                                    }}
                                    className={classes.SaveButtonMobile}
                                    disabled={saveButtonIsClicked}
                                    onClick={() => onSaveHandler()}
                                >
                                    Save
                                </button>
                            </>
                        )}
                    </div>

                </div>
                <div>
                    {!isReadOnly && (<div className={classes.ActionButtonsContainer}>
                        <button
                            className={classes.ChangeLayoutButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => setShowChangeLayoutModal(true)}
                        >
                            Change Layout
                        </button>
                        <button
                            style={{
                                background: `linear-gradient(91.3deg, ${props.themeColor} 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                            }}
                            className={classes.SaveButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => onSaveHandler()}
                        >
                            Save
                        </button>
                    </div>)}
                </div>
            </div>
        </div>

        {
            showAddFieldModal && (<AddFieldModal
                setShowModal={setShowAddFieldModal}
                themeColor={props.themeColor}
                profileFields={companyFields}
                onAddProfileField={onAddProfileField}
            />)
        }

        {
            showChangeLayoutModal && (<ChangeLayoutModal
                setShowModal={setShowChangeLayoutModal}
                themeColor={props.themeColor}
                profileFields={companyFields.filter(field => field.privacyMode !== PROFILE_DATA_PRIVACY_MODE.PRIVATE,)}
                onUpdateProfileFields={onUpdateProfileFields}
            />)
        }
    </div>)
        ;
};

const mapStateToProps = state => {
    return {
        themeColor: state.home.status.themeColor, //profilePicture: state.profile.profilePicture,
        loading: state.teams.loading,
        companyDetails: state.teams.companyDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCompanyDetails: () => dispatch(getCompanyDetails()),
        onUploadFile: file => dispatch(actions.uploadPublicFile(file)),
        onUpdateCompanyDetails: (data) => dispatch(updateCompanyDetails(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsNew);
