import React from 'react';
import classes from './StatusChangeConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {connect} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {createGradient} from "../../../../../common/constants";
import {PROFILE_STATUS} from "../../../../../common/enums";

const ShareableProfileDeactivation = props => {
    const profileActive = props.status.profileStatus === PROFILE_STATUS.ACTIVE;

    const onChangeHandler = () => {
        const newStatus = profileActive ? PROFILE_STATUS.INACTIVE : PROFILE_STATUS.ACTIVE;
        props.onChangeProfileStatus(props.userId, newStatus);
        props.onClose();
    };

    return (
        <div className={classes.StatusChangeConfirmation}>
            <div className={classes.CloseButtonContainer}>
                <div onClick={props.onClose}>
                    <MdOutlineClose size={22} color="#C5C5C5"/>
                </div>
            </div>
            <p className={classes.ConfirmationText}>
                Do you want to {profileActive ? 'deactivate' : 'activate'} your
                <br/>
                profile?
            </p>
            <p className={classes.InfoText}>
                {profileActive
                    ? 'Shareable Profile will be disabled after deactivation'
                    : 'Shareable Profile will be enabled after activation'}
            </p>
            <button
                style={{background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(props.themeColor, 50)} 100%)`}}
                className={classes.DeactivateButton}
                onClick={onChangeHandler}>
                {profileActive ? 'Yes, Deactivate' : 'Activate'}
            </button>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userId: state.auth.user.id,
        status: state.home.status,
        themeColor: state.home.status.themeColor,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeProfileStatus: (userId, status) =>
            dispatch(actions.changeProfileStatus(userId, status)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShareableProfileDeactivation);
