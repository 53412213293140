import React from 'react';
import classes from './LoginHeader.module.scss';
import SVG from "react-inlinesvg";
import {TENANT_CONFIG} from "../../../../common/constants";
import {FullLogo} from "../../../../common/assetObjects";

const LoginHeader = () => {
  return (
    <div className={classes.LoginHeaderContainer}>
      <div className={classes.LoginHeader}>
        <h1>
          Connections are just
          <br />
          one tap away
        </h1>
        <div className={classes.TopBox}>
            <SVG fill={'white'} width={160} src={FullLogo}
                 alt={TENANT_CONFIG.NAME}/>
        </div>
        <div className={classes.MiddleBox}></div>
        <div className={classes.BottomBox}></div>
      </div>
    </div>
  );
};

export default LoginHeader;
