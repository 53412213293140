import classes from "./ContactDeleteConfirmPopup.module.scss";
import {MdOutlineClose} from "react-icons/md";
import React from "react";
import * as actions from "store/actions";
import {connect} from "react-redux";
import {showToast} from "common/utils";

const ContactDeleteConfirmPopup = (props) => {

  const onDeleteHandler = successCallback => {
    try {
      props.onDeleteContact(props.contactId, successCallback);
      showToast("Contact deleted successfully!", props.color);
      props.onClose();
    } catch (err) {
      showToast("Something went wrong!", props.color);
      props.onClose();
    }
  }



  return (
    <div className={classes.DeleteConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
      <p className={classes.ConfirmationText}>
        Delete Confirmation
      </p>
      <p className={classes.InfoText}>
        Are you sure you want to Delete this Contact?
      </p>
      <button className={classes.DeleteButton} onClick={onDeleteHandler}>
        Yes, Delete
      </button>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onDeleteContact: (contactId, successCallback) => dispatch(actions.deleteContact(contactId, successCallback)),
  };
};

export default connect(null, mapDispatchToProps)(ContactDeleteConfirmPopup);
