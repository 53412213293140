import classes from "./ForgotPasswordHeader.module.scss";
import PasswordForgot from "../../../../assets/images/password-confirm.svg";
import React from "react";

const ForgotPasswordHeader = () => {
  return (
    <div className={classes.ForgotPasswordHeaderContainer}>
      <div className={classes.ForgotPasswordHeader}>
        <img src={PasswordForgot} alt="Forgot Password"/>
      </div>
    </div>
  );
};

export default ForgotPasswordHeader;
