import axios from '../../../common/axios';

export const uploadFile = (file, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        const formData = new FormData();
        formData.append('file', file);

        return axios
            .post('/api/user-service/upload-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const uploadPublicFile = (file, successCallback = () => {}, errorCallback = () => {}) => {
    return dispatch => {
        const formData = new FormData();
        formData.append('file', file);

        return axios
            .post('/api/user-service/upload-public-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const saveProfile = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/user-service/create-profile', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const updateProfile = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/user-service/update-profile', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const deleteProfile = (profileId, successCallback, showToast) => {
    return dispatch => {
        return axios
            .delete(`/api/user-service/delete-profile?profileId=${profileId}`)
            .then(() => {
                if (successCallback) successCallback();
            })
            .catch(err => {
                showToast('Cannot delete active profile')
                console.log(err)
                throw err;
            });
    };
};
