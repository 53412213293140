import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';

import {applyMiddleware, combineReducers, compose, legacy_createStore as createStore,} from 'redux';
import {thunk} from 'redux-thunk';
import {Provider} from 'react-redux';

import authReducer from './store/reducers/auth';
import homeReducer from './store/reducers/home';
import contactsReducer from './store/reducers/contact';
import productsReducer from './store/reducers/products';
import accountReducer from './store/reducers/account';
import teamsReducer from './store/reducers/teams';
import analyticsReducer from "./store/reducers/analytics";
import settingsReducer from './store/reducers/settings';
import * as actionTypes from "./store/actions/auth/actionTypes";
import {ConfigProvider, theme} from "antd";
import {
    PRIMARY_COLOR,
    QUATERNARY_COLOR,
    QUINARY_COLOR,
    SECONDARY_COLOR,
    TENANT_CONFIG,
    TERTIARY_COLOR
} from "./common/constants";

window.addEventListener('load', () => {
    const tenantId = parseInt(process.env.REACT_APP_TENANT_ID || '1', 10);
    let favicon = '/static/images/commercial-prime-logo.png';
    let appleTouchIcon = '/static/images/commercial-prime-logo.png';

    if (tenantId === 2) {
        favicon = '/static/images/osest-logo.png';
        appleTouchIcon = '/static/images/osest-logo.png';
    }

    const faviconLink = document.querySelector("link[rel='icon']");
    if (faviconLink) {
        faviconLink.href = favicon;
    }

    const appleTouchLink = document.querySelector("link[rel='apple-touch-icon']");
    if (appleTouchLink) {
        appleTouchLink.href = appleTouchIcon;
    }
});

const appReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    contact: contactsReducer,
    products: productsReducer,
    account: accountReducer,
    teams: teamsReducer,
    analytics: analyticsReducer,
    settings: settingsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.SIGN_OUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

if (PRIMARY_COLOR) document.documentElement.style.setProperty('--primary-color', PRIMARY_COLOR);
if (SECONDARY_COLOR) document.documentElement.style.setProperty('--secondary-color', SECONDARY_COLOR);
if (TERTIARY_COLOR) document.documentElement.style.setProperty('--tertiary-color', TERTIARY_COLOR);
if (QUATERNARY_COLOR) document.documentElement.style.setProperty('--quaternary-color', QUATERNARY_COLOR);
if (QUINARY_COLOR) document.documentElement.style.setProperty('--quinary-color', QUINARY_COLOR);
document.title = TENANT_CONFIG.NAME;

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
