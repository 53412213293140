import React from 'react';
import classes from './ProfileCard.module.scss';
import {BsEye} from 'react-icons/bs';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {ROUTES_PATHS} from "../../../../../common/enums";
import {showToast} from "../../../../../common/utils";

const ProfileCard = props => {
    const navigate = useNavigate();
    const location = useLocation();

    const {profile, isActive, belongsToCompany = false} = props;
    const firstName = profile.data.find(dataObj => dataObj.name === 'First Name');
    const lastName = profile.data.find(dataObj => dataObj.name === 'Last Name');
    const title = profile.data.find(dataObj => dataObj.name === 'Title');
    const company = profile.data.find(dataObj => dataObj.name === 'Company');

    const onSelectProfileHandler = () => {
        navigate(`${ROUTES_PATHS.USER.EDIT_PROFILE}/${profile.id}`);
    };

    const onViewProfileHandler = event => {
        event.stopPropagation();
        if (props.products.length === 0) {
            showToast("You don't have any active products, please link a product and try again", props.themeColor);
        } else {
            props.showConfirmation(profile.id);
        }
    };

    return (
        <div
            className={`${classes.ProfileCard}`}
            style={{
                borderColor: isActive ? props.themeColor : '#3d3d3d',
                backgroundImage: 'none'
            }}
            onClick={onSelectProfileHandler}
        >
            <div className={classes.ProfileContainer}>
                <div className={classes.CardHeader}>
                    {!location.pathname.includes(ROUTES_PATHS.COMPANY_ADMIN.TEAMS) && (
                        <>
                            <div
                                className={classes.ViewButton}
                                onClick={onViewProfileHandler}
                            >
                                <BsEye color="#A8A8A8" size={16} />
                            </div>
                            <div
                                className={isActive ? classes.StatusActive : classes.StatusInactive}
                                onClick={event => {
                                    props.onChangeActiveProfileHandler(profile.id);
                                    event.stopPropagation();
                                }}
                            >
                                <p>Inactive</p>
                                <p style={{
                                    backgroundColor: isActive ? props.themeColor : null,
                                    borderColor: isActive ? props.themeColor : null
                                }}>
                                    Active
                                </p>
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={isActive ? classes.ProfileInfoContainerActive : classes.ProfileInfoContainerInactive}
                    style={isActive ? {color: props.themeColor} : {}}
                >
                    <h2>{firstName?.value} {lastName?.value}</h2>
                    <p>{title?.value}</p>
                    <span>{company?.value}</span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    userId: state.auth.user.id,
    themeColor: state.home.status.themeColor,
    tenantName: state.home.status.tenantName,
    products: state.products.products
});

export default connect(mapStateToProps)(ProfileCard);
