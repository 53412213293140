import React, {useEffect, useState} from 'react';
import {Button, Select, Tooltip} from 'antd';
import {MdArrowDropDown, MdCheck, MdOutlineClose} from 'react-icons/md';
import {IconPickerItem} from 'react-fa-icon-picker';
import classes from './ChangeLayoutModal.module.scss';
import {adjustColorBrightness, createGradient} from "../../../common/constants";
import Backdrop from "../../UI/Backdrop/Backdrop";
import {DATA_FIELD_TYPE, PROFILE_FIELD_LAYOUT_TYPE} from "../../../common/enums";
import {FaXTwitter} from "react-icons/fa6";

const ChangeLayoutModal = (props) => {
    const [adjustColor, setAdjustColor] = useState("");
    const [selectedFields, setSelectedFields] = useState([]);

    const profileFieldOptions = props.profileFields
    .filter(field => [DATA_FIELD_TYPE.LINK, DATA_FIELD_TYPE.CONTACT_NUMBER].includes(field.type) && field.name !== 'Contact Number')
    .map(field => ({
        label: field.name,
        value: field.name,
    }));

    useEffect(() => {
        setAdjustColor(adjustColorBrightness(props.themeColor, 60));
        const preselectedFields = props.profileFields
            .filter(field => field.layout === PROFILE_FIELD_LAYOUT_TYPE.ICON)
            .map(field => field.name);
        setSelectedFields(preselectedFields);
    }, [props.themeColor, props.profileFields]);

    const handleFieldChange = (value) => {
        setSelectedFields(value);
    };

    const saveLayoutHandler = () => {
        const updatedFields = props.profileFields.map(field => {
            const isSelected = selectedFields.includes(field.name);
            return {
                name: field.name,
                layout: isSelected ? PROFILE_FIELD_LAYOUT_TYPE.ICON : PROFILE_FIELD_LAYOUT_TYPE.LIST,
            };
        });

        props.onUpdateProfileFields(updatedFields);
        console.log(props.profileFields);
        props.setShowModal(false);
    };

    return (
        <Backdrop onClick={() => props.setShowModal(false)}>
            <div className={classes.ChangeLayoutModal}>
                <div className={classes.MainContainer}>
                    <div className={classes.CloseButtonContainerRight}>
                        <div onClick={() => props.setShowModal(false)}>
                            <MdOutlineClose size={20} color="#C5C5C5"/>
                        </div>
                    </div>
                    <div className={classes.MainContainerContent}>
                        <div className={classes.InputContainer}>
                            <h6>Change Field Layout</h6>
                            {selectedFields.length > 0 && <div className={classes.IconDisplayContainer}>
                                {selectedFields.map(fieldName => {
                                    const field = props.profileFields.find(f => f.name === fieldName);
                                    return (
                                        <Tooltip title={field.value} key={field.name}>
                                            <div className={classes.IconWrapper}
                                                 style={{borderColor: props.themeColor}}>{(field.icon !== undefined && field.icon !== null) && (
                                                (field.icon === "FaXTwitter") ?
                                                    <FaXTwitter color={props.themeColor} size={18}/> :
                                                    <IconPickerItem icon={field.icon} color={props.themeColor}
                                                                    size={18}/>
                                            )}</div>
                                        </Tooltip>

                                    );
                                })}
                            </div>}
                            <div className={classes.InfoContainer}>
                                <p>Select the fields you wish to show as icons<br/>in the shareable profile
                                </p>
                                <p>
                                    <small>(Only the fields set as <strong>Public</strong> can be selected)</small>
                                </p>
                            </div>

                            <div className={classes.InputWrapper}
                                 style={{
                                     background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(
                                         adjustColor,
                                         80,
                                     )} 100%)`,
                                 }}>
                                <Select
                                    menuItemSelectedIcon={<MdCheck color={props.themeColor} size={18}/>}
                                    suffixIcon={<MdArrowDropDown color={"#C5C5C5"} size={28}/>}
                                    removeIcon={<MdOutlineClose color={"#C5C5C5"} size={14}/>}
                                    mode="multiple"
                                    placeholder="Select Fields"
                                    value={selectedFields}
                                    onChange={handleFieldChange}
                                    style={{width: '100%', minHeight: 50}}
                                    options={profileFieldOptions}
                                />
                            </div>
                        </div>
                        <Button
                            type="primary"
                            className={classes.SaveFieldButton}
                            onClick={saveLayoutHandler}
                            style={{
                                background: `linear-gradient(142deg, ${props.themeColor} 0%, ${createGradient(
                                    props.themeColor,
                                    50
                                )} 100%)`,
                                borderColor: props.themeColor,
                            }}
                        >
                            Save Layout
                        </Button>
                    </div>
                </div>
            </div>
        </Backdrop>
    );
};

export default ChangeLayoutModal;
