import React from 'react';
import classes from './Header.module.scss';
import {HiMenuAlt2} from 'react-icons/hi';
import {RiArrowLeftSLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import {ROUTES_PATHS} from "../../../../../common/enums";

const Header = props => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back to the previous page
    } else {
      navigate(ROUTES_PATHS.USER.HOME); // Navigate to the home page if history stack is empty
    }
  };


  return (
    <div className={classes.Header}>
      <div
        className={classes.SideButtonContainer}
        onClick={handleBackButtonClick}
      >
          <RiArrowLeftSLine size={24} color="#BCBCBC"/>
      </div>
      <p className={classes.Title}>Account</p>
      <div className={classes.SideButtonContainer} onClick={props.toggleMenu}>
          <HiMenuAlt2 size={24} color="#BCBCBC"/>
      </div>
    </div>
  );
};

export default Header;
