import React from 'react';
import classes from './RegisterHeader.module.scss';
import {useSearchParams} from "react-router-dom";
import {PRIMARY_COLOR, TENANT_CONFIG} from "../../../../common/constants";
import SVG from "react-inlinesvg";
import {FullLogo} from "../../../../common/assetObjects";

const RegisterHeader = () => {
    const [searchParams] = useSearchParams();
    const logo = searchParams.get('logo');
    const color = (searchParams.get('color') !== 'null' && searchParams.get('color') !== null) ? `#${searchParams.get('color')}` : PRIMARY_COLOR;
    const gradient = `linear-gradient(114.54deg, #000000 0.12%, ${color + '5B'} 100%)`;
    const imageStyle = {display: logo === 'true' ? "none" : undefined};

    return (
        <div className={classes.RegisterHeaderContainer} style={{background: gradient}}>
            <div className={classes.RegisterHeader}>
                <h1>
                    That One Card,
                    <br/>
                    Every Entrepreneur Needs
                </h1>
                <div className={classes.TopBox}>
                    <SVG fill={'white'} width={160} src={FullLogo}
                         alt={TENANT_CONFIG.NAME}/>
                </div>
                <div className={classes.MiddleBox}></div>
                <div className={classes.BottomBox}></div>
            </div>
        </div>
    );
};

export default RegisterHeader;
