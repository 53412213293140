import React from 'react';
import classes from './Header.module.scss';
import {HiMenuAlt2} from 'react-icons/hi';
import {RiArrowLeftSLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import {ROUTES_PATHS} from "../../../../../common/enums";

const Header = props => {
  const navigate = useNavigate();

  return (
    <div className={classes.Header}>
      <div
        className={classes.SideButtonContainer}
        onClick={() => navigate(ROUTES_PATHS.USER.HOME)}
      >
        <RiArrowLeftSLine size={25} color="#BCBCBC" />
      </div>
      <p className={classes.Title}>Analytics</p>
      <div className={classes.SideButtonContainer} onClick={props.toggleMenu}>
        <HiMenuAlt2 size={24} color="#BCBCBC" />
      </div>
    </div>
  );
};

export default Header;
