import React, {useRef} from 'react';
import classes from './ShareableLink.module.scss';
import Header from './Header/Header';
import {RiArrowGoForwardLine} from 'react-icons/ri';
import {MdOutlineContentCopy, MdOutlineModeEditOutline} from 'react-icons/md';
import {useNavigate, useOutletContext} from 'react-router-dom';
import BackgroundTop from '../../../../assets/images/create-profile/background-top.png';
import BackgroundBottom from '../../../../assets/images/create-profile/background-bottom.png';
import LinkedProductCard from "./LinkedProductsCard/LinkedProductCard";
import {connect} from 'react-redux';
import copy from 'copy-to-clipboard';
import {adjustColorBrightness, createGradient, TENANT_CONFIG} from "../../../../common/constants";
import {showToast} from "../../../../common/utils";
import {BsCart, BsPlus} from "react-icons/bs";
import {ROUTES_PATHS} from "../../../../common/enums";

const ShareableLink = (props) => {
    const outletContext = useOutletContext();
    const buttonRef = useRef(null);
    const linkText = process.env.REACT_APP_SHAREABLE_VIEW_URL;
    const navigate = useNavigate();

    const copyToClipboard = () => {
        const link = linkText + props.user;
        copy(link);
        showToast("Copied to clipboard", props.themeColor)
    }

    const onNavigate = path => {
        navigate(path);
    };

    return (
        <div className={classes.ShareableLink}>
            <Header toggleMenu={outletContext.toggleMenu}/>
            <div style={{backgroundColor: props.themeColor}} className={classes.LeftContainer}>
                <div className={classes.LeftInnerContainer}>
                    <img src={BackgroundTop} alt="background top"/>
                    <img src={BackgroundBottom} alt="background bottom"/>
                    <p style={{marginBottom: 4}}><strong>Simply Copy & Share</strong>!</p>
                    <p>
                        Share this link with anyone so that they can view your public profile
                    </p>
                </div>
                <div style={{background: createGradient(adjustColorBrightness(props.themeColor, 90), 40)}}
                     className={classes.LinkListItemWrapper}>
                    <div className={classes.LinkListItem}>
                        <div className={classes.LinkContainer}>
                            <div className={classes.Link}>
                                <p>Shareable Link</p>
                                <p style={{color: props.themeColor}}>
                                    {linkText}<span>{props.user}</span>
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <div ref={buttonRef} onClick={copyToClipboard} className={classes.Button}>
                                        <MdOutlineContentCopy size={17} color={props.themeColor}/>
                                        <p>Copy Link</p>
                                    </div>

                                    <div className={classes.Button}
                                         onClick={() => window.open(linkText + props.user, '_blank')}>
                                        <RiArrowGoForwardLine size={17} color={props.themeColor}/>
                                        <p>Preview</p>
                                    </div>
                                </div>
                                <div className={classes.EditButton} onClick={() => {
                                    onNavigate(ROUTES_PATHS.USER.ACCOUNT)
                                }}>
                                    <MdOutlineModeEditOutline size={17} color={props.themeColor}/>
                                    <p>Edit</p>
                                </div>
                                <div className={classes.EditButtonSecondary}>
                                    <MdOutlineModeEditOutline size={17} color={props.themeColor}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.RightContainer}>
                <div className={classes.BuyButtonContainer}>
                    <h1>Linked Products</h1>
                    <div>
                        <button
                            onClick={() => {
                                showToast(`Please contact us at: ${TENANT_CONFIG.CONTACT_EMAIL} to link new products`, props.themeColor)
                            }}
                        >
                            <BsPlus size={20} color={props.themeColor}/>
                            &ensp;Link Product
                        </button>
                        <button
                            style={{backgroundColor: props.themeColor}}
                            onClick={() => window.open(props.tenantWebsite, '_blank')}
                        >
                            <BsCart size={14}/>
                            &ensp;Shop Now
                        </button>
                    </div>
                </div>
                <div>
                    {props.products.map(product => {
                        return (
                            <LinkedProductCard companyName={props.tenantName?.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                               productLink={product.link}
                                               productName={product.name}
                                               color={props.themeColor} key={product.link}/>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        products: state.products.products,
        themeColor: state.home.status.themeColor,
        companyName: state.home.companyName,
        tenantWebsite: state.home.status.tenantWebsite,
        tenantName: state.home.status.tenantName,
    };
};


export default connect(mapStateToProps)(ShareableLink);
