import React from 'react';
import classes from './DeleteConfirmation.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../common/constants";

const DeleteConfirmation = props => {
  return (
    <div className={classes.DeleteConfirmation}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5" />
        </div>
      </div>
        <p className={classes.ConfirmationText}>Delete Profile</p>
      <p className={classes.InfoText}>
          Are you sure you want to delete this profile?
      </p>
      <button
        style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color,50)} 100%)`}}
        className={classes.ContinueButton}
        onClick={props.onConfirmation}>
        Yes, Delete
      </button>
    </div>
  );
};

export default DeleteConfirmation;
