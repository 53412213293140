import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {MdKeyboardArrowRight, MdOutlineClose} from 'react-icons/md';
import {FaPowerOff} from 'react-icons/fa';
import classes from './PrimeMenu.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';
import LogOutConfirmation from '../../Dashboard/DashboardHeader/LogOutConfirmation/LogOutConfirmation';
import InstallPWAButton from '../PWAInstallButton/InstallPWAButton';
import {adjustColorBrightness, TENANT_CONFIG} from '../../../common/constants';
import {MENU_ITEMS} from "../PrimeTabMenu/PrimeDesktopMenu";
import {USER_ROLE} from "../../../common/enums";

const PrimeMobileMenu = ({menuOpen, toggleMenu, themeColor, role}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [logoutConfirmationPopupVisible, setLogoutConfirmationPopupVisible] = useState(false);
    const isCompanyAdmin = role === USER_ROLE.COMPANY_ADMIN;
    const menuClasses = [classes.PrimeMenu, menuOpen && classes.PrimeMenuOpen].join(' ');

    const handleNavigate = (path) => {
        navigate(path);
        toggleMenu();
    };

    const isActive = (path) => location.pathname.includes(path);

    const renderMenuItem = (item) => (
        <div
            key={item.path}
            style={{
                borderColor: adjustColorBrightness(themeColor, 100),
                width: '100px',
            }}
            className={classes.Option}
            onClick={() => handleNavigate(item.path)}
        >
            {React.cloneElement(item.icon, {
                style: {fill: isActive(item.path) ? "white" : themeColor},
            })}
            <p style={{color: isActive(item.path) ? "white" : themeColor}}>{item.label}</p>
        </div>
    );

    return (
        <>
            {menuOpen && <div className={classes.Backdrop} onClick={toggleMenu}></div>}

            {logoutConfirmationPopupVisible && (
                <Backdrop onClick={() => setLogoutConfirmationPopupVisible(false)}>
                    <LogOutConfirmation themeColor={themeColor}
                                        onClose={() => setLogoutConfirmationPopupVisible(false)}/>
                </Backdrop>
            )}

            <div className={menuClasses}>
                <div className={classes.MenuHeader}>
                    <div className={classes.CloseButtonContainer} onClick={toggleMenu}>
                        <MdOutlineClose size={22} color={themeColor}/>
                    </div>
                </div>

                <div className={classes.Items}>
                    {MENU_ITEMS.USER.map(renderMenuItem)}
                    {isCompanyAdmin && (MENU_ITEMS.COMPANY_ADMIN.map(renderMenuItem))}
                </div>

                <div className={classes.LinkContainer}
                     onClick={() => window.open(TENANT_CONFIG.WEBSITE_URL, '_blank')}>
                    <div>
                        <p>Visit {TENANT_CONFIG.NAME} Website</p>
                        <MdKeyboardArrowRight color={themeColor} size={18}/>
                    </div>
                </div>

                <InstallPWAButton small={false} styles={classes.PWAButton}/>

                <div className={classes.LogOutButton}>
                    <FaPowerOff
                        style={{cursor: 'pointer'}}
                        size={30}
                        onClick={() => {
                            toggleMenu();
                            setLogoutConfirmationPopupVisible(true);
                        }}
                    />
                </div>
            </div>


        </>
    )
        ;
};

export default PrimeMobileMenu;
