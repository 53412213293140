import classes from "./ChangePasswordPopup.module.scss";
import {MdClose} from "react-icons/md";
import {createGradient} from "common/constants";
import {GoKey} from "react-icons/go";
import {FaLock} from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import React, {useState} from "react";
import {connect} from "react-redux";
import * as actions from 'store/actions';
import {RiEyeFill, RiEyeOffFill} from 'react-icons/ri';
import {showToast} from "../../../../../common/utils";

const ChangePasswordPopup = (props) => {
    const [currentPassword, setcurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [reEnterPassword, setReEnterPassword] = React.useState('');
    const [isCurrentPasswordShown, setIsCurrentPasswordShown] = useState(false);
    const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
    const [isReEnterPasswordShown, setIsReEnterPasswordShown] = useState(false);

    const [isSaving, setIsSaving] = useState(false);


    const onClickHandler = async () => {
        if (formIsValid()) {
            try {
                setIsSaving(true);
                const res = await props.onChangePassword({currentPassword, newPassword});
                showToast('Password updated successfully', props.themeColor);
                props.onClose();
            } catch (err) {
                if (err?.response?.data.errorCode === 'INVALID_PASSWORD') showToast('Current password is invalid', props.themeColor)
                else showToast('Failed to update password', props.themeColor);
            } finally {
                setIsSaving(false);
            }
        }
    }

    const formIsValid = () => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@#$%^&+=_\-!¡?¿;:'",.<>\{\}\[\]\\/\|~`(•√π÷×¶∆£¢€¥^°={}\*]).{8,}$/;

        if (currentPassword === '') {
            showToast('Current Password cannot be empty', props.themeColor);
            return false;
          } else if (newPassword === '') {
            showToast('New Password cannot be empty', props.themeColor);
            return false;
          } else if (!newPassword.match(passwordRegex)) {
            showToast('Password should have at least 8 characters and include letters, numbers, and special characters', props.themeColor);
            return false;
          } else if (newPassword === currentPassword) {
            showToast('New password cannot be the same as current password', props.themeColor);
            return false;
          } else if (newPassword !== reEnterPassword) {
            showToast('Passwords do not match', props.themeColor);
            return false;
          }
        return true;
    };


    const viewCurrentPasswordOnClickHandler = () => {
        setIsCurrentPasswordShown(!isCurrentPasswordShown);
    };

    const viewNewPasswordOnClickHandler = () => {
        setIsNewPasswordShown(!isNewPasswordShown);
    };

    const viewReEnterPasswordOnClickHandler = () => {
        setIsReEnterPasswordShown(!isReEnterPasswordShown);
    };

    return (
        <div className={classes.ChangePasswordPopup}>
            <div className={classes.CloseButtonContainer}>
                <div onClick={() => !isSaving && props.onClose()} style={{opacity: isSaving ? 0.5 : 1}}>
                    <MdClose color="#C5C5C5" size={20}/>
                </div>
            </div>
            <p className={classes.Title}>Change Password</p>

            <div
                style={{
                    background: `linear-gradient(142deg, 
          ${props.themeColor} 0%, 
          ${createGradient(props.themeColor, 80)} 
          100%)`
                }}
                className={classes.InputWrapper}>
                <div>
                    <div className={classes.IconContainer}>
                        <FaLock size={16}/>
                    </div>
                    <input
                        placeholder="Current Password"
                        inputMode="text"
                        type={isCurrentPasswordShown ? 'text' : 'password'}
                        value={currentPassword}
                        onChange={e => setcurrentPassword(e.target.value)}
                    />
                    <div
                        className={classes.ViewPassword}
                        onClick={viewCurrentPasswordOnClickHandler}
                    >
                        {isCurrentPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
                    </div>
                </div>
            </div>

            <div
                style={{
                    background: `linear-gradient(142deg, 
          ${props.themeColor} 0%, 
          ${createGradient(props.themeColor, 80)} 
          100%)`
                }}
                className={classes.InputWrapper}
            >
                <div>
                    <div className={classes.IconContainer}>
                        <GoKey size={13}/>
                    </div>
                    <input
                        placeholder="New Password"
                        inputMode="text"
                        type={isNewPasswordShown ? 'text' : 'password'}
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    <div
                        className={classes.ViewPassword}
                        onClick={viewNewPasswordOnClickHandler}
                    >
                        {isNewPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
                    </div>
                </div>
            </div>

            <div
                style={{
                    background: `linear-gradient(142deg, 
        ${props.themeColor} 0%, 
        ${createGradient(props.themeColor, 80)} 
        100%)`
                }}
                className={classes.InputWrapper}
            >
                <div>
                    <div className={classes.IconContainer}>
                        <GoKey size={13}/>
                    </div>
                    <input
                        placeholder="Re-enter Password"
                        inputMode="text"
                        type={isReEnterPasswordShown ? 'text' : 'password'}
                        value={reEnterPassword}
                        onChange={e => setReEnterPassword(e.target.value)}
                    />
                    <div
                        className={classes.ViewPassword}
                        onClick={viewReEnterPasswordOnClickHandler}
                    >
                        {isReEnterPasswordShown ? <RiEyeOffFill/> : <RiEyeFill/>}
                    </div>
                </div>
            </div>


            <button
                disabled={isSaving}
                style={{
                    background: `linear-gradient(91.3deg, 
          ${props.themeColor} 1.61%, 
          ${createGradient(props.themeColor, 50)} 
          100%)`
                }}
                className={classes.SaveButton}
                onClick={onClickHandler}
            >
                {isSaving ? (
                    <div className={classes.SpinnerContainer}>
                        <ClipLoader color={'white'} loading={true} size={20}/>
                    </div>
                ) : (<span>Save</span>)}
            </button>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        themeColor: state.home.status.themeColor,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (data) =>
            dispatch(actions.changePassword(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopup);
