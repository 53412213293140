import React from "react";
import classes from "./ProfileLimitReached.module.scss";
import {MdOutlineClose} from "react-icons/md";
import {GiPadlock} from "react-icons/gi";
import {createGradient, TENANT_CONFIG} from "../../../common/constants";

const ProfileLimitReached = (props) => {
  return (
    <div className={classes.ProfileLimitReached}>
      <div className={classes.CloseButtonContainer}>
        <div onClick={props.onClose}>
          <MdOutlineClose size={22} color="#C5C5C5"/>
        </div>
      </div>
        <GiPadlock size={60} color={props.themeColor}/>
      <p className={classes.MainText}>
        You have reached<br/>the max no. of profiles allowed.
      </p>
        <p className={classes.InfoText}>Please contact {TENANT_CONFIG.NAME}<br/>to add more!</p>
      <button
        style={{
          background: `linear-gradient(
                142deg,
                ${props.themeColor} 0%,
                ${createGradient(props.themeColor, 50)} 100%`
        }}
        className={classes.GotItButton}
        onClick={() => window.open(TENANT_CONFIG.WEBSITE_URL, '_blank')}
      >
        Got It!
      </button>

    </div>
  );
};

export default ProfileLimitReached;
