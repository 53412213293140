import {TENANT_CONFIG} from "./constants";

// Asset urls of all tenants
import CardBackground1 from '../assets/images/tenants/commercial-prime/card-bg.png';
import CardBackground2 from '../assets/images/tenants/osest/card-bg.png';

import FullLogo1 from "../assets/images/tenants/commercial-prime/full-logo-black.svg";
import FullLogo2 from "../assets/images/tenants/osest/full-logo.svg";

import Logo1 from "../assets/images/tenants/commercial-prime/logo-black.svg";
import Logo2 from "../assets/images/tenants/osest/logo.svg";

import QrIcon1 from "../assets/images/tenants/commercial-prime/qr-icon.jpg";
import QrIcon2 from "../assets/images/tenants/osest/qr-icon.png";


const TENANT_ASSETS = {
    1: {
        CardBackground: CardBackground1,
        FullLogo: FullLogo1,
        Logo: Logo1,
        QrIcon: QrIcon1,
    },
    2: {
        CardBackground: CardBackground2,
        FullLogo: FullLogo2,
        Logo: Logo2,
        QrIcon: QrIcon2,
    },
};

const tenantId = parseInt(TENANT_CONFIG.ID, 10);

const tenantAssets = TENANT_ASSETS[tenantId] || TENANT_ASSETS[1];

export const CardBackground = tenantAssets.CardBackground;
export const FullLogo = tenantAssets.FullLogo;
export const Logo = tenantAssets.Logo;
export const QrIcon = tenantAssets.QrIcon;