import * as actionTypes from '../actions/contacts/actionTypes';

const initialState = {
    contacts: null,
    loading: false,
    exportImportDropdownVisible: false,
    selectedId: null,
};

const getAllContactsStart = (state) => {
    return {
        ...state,
        loading: true,
    };
};

const getAllContactsSuccess = (state, action) => {
    return {
        ...state,
        loading: false,
        contacts: action.contacts,
    };
};

const getAllContactsFail = (state) => {
    return {
        ...state,
        loading: false,
    };
};

const toggleExportImportDropdown = (state) => {
    return {
        ...state,
        exportImportDropdownVisible: !state.exportImportDropdownVisible,
    };
};

const changeSelectedId = (state, action) => {
    return {
        ...state,
        selectedId: action.payload,
    };
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONTACTS_START:
            return getAllContactsStart(state);
        case actionTypes.GET_CONTACTS_SUCCESS:
            return getAllContactsSuccess(state, action);
        case actionTypes.GET_CONTACTS_FAIL:
            return getAllContactsFail(state);
        case actionTypes.CHANGE_SELECTED_ID:
            return changeSelectedId(state, action);
        case actionTypes.TOGGLE_EXPORT_IMPORT_DROPDOWN:
            return toggleExportImportDropdown(state);
        default:
            return state;
    }
};

export default reducer;
