import React from 'react';
import classes from './Header.module.scss';
import {HiMenuAlt2} from 'react-icons/hi';
import {useNavigate} from "react-router-dom";
import {ROUTES_PATHS} from "../../../../../common/enums";
import {RiArrowLeftSLine} from "react-icons/ri";

const Header = props => {
  const navigate = useNavigate();
  return (
      <div className={classes.Header}>
          <div
              className={classes.SideButtonContainer}
              onClick={() => navigate(ROUTES_PATHS.USER.HOME)}
          >
              <RiArrowLeftSLine size={25} color="#BCBCBC"/>
          </div>
          {/*<div>*/}
          {/*    <HiOutlineChevronLeft onClick={() => navigate(ROUTES_PATHS.USER.HOME)}/>*/}
          {/*    <div*/}
          {/*      className={classes.SideButtonContainer}*/}
          {/*      onClick={props.toggleDropdownVisibility}*/}
          {/*    >*/}
          {/*      <BiDotsVerticalRounded size={24} color="#BCBCBC" />*/}
          {/*    </div>*/}
          {/*</div>*/}
          <p className={classes.Title}>Contacts</p>
          <div className={classes.SideButtonContainer} onClick={props.toggleMenu}>
              <HiMenuAlt2 size={24} color="#BCBCBC"/>
          </div>
      </div>
  );
};

export default Header;
