import React, { useEffect } from 'react';
import classes from './RegisterPage.module.scss';
import Div100vh from 'react-div-100vh';

import RegisterHeader from '../../components/Auth/Register/RegisterHeader/RegisterHeader';
import RegisterForm from '../../components/Auth/Register/RegisterForm/RegisterForm';
import { logoutUser } from '../../common/utils';

const Register = () => {
  useEffect(() => {
    // Check if user is logged in and log out
    logoutUser();
  }, []);

  return (
    <Div100vh className={classes.RegisterPage}>
      <RegisterHeader />
      <RegisterForm />
    </Div100vh>
  );
};

export default Register;