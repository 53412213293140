import classes from './ThemeColorLock.module.scss';
import {MdOutlineClose} from 'react-icons/md';
import {createGradient} from "../../../../../common/constants";

const ThemeColorLockPopup = props => {
    return (
        <div className={classes.ThemeColorLock}>
            <div className={classes.CloseButtonContainer}>
                <div onClick={props.onClose}>
                    <MdOutlineClose size={22} color="#C5C5C5"/>
                </div>
            </div>
            <p className={classes.ConfirmationText}>Cannot update theme</p>
            <p className={classes.InfoText}>
                Your account theme is managed by your company branding
            </p>
            <button
                style={{background: `linear-gradient(142deg, ${props.color} 0%, ${createGradient(props.color, 50)} 100%)`}}
                className={classes.ContinueButton}
                onClick={props.onClose}
            >
                Sure
            </button>
        </div>
    );
};

export default ThemeColorLockPopup;
