import React from 'react';
import classes from './ProfileCard.module.scss';
import {FaUser} from 'react-icons/fa';
import {FiChevronRight} from 'react-icons/fi';
import {adjustColorBrightness, createGradient} from "../../../common/constants";
import {USER_ROLE} from "../../../common/enums";
import {CardBackground} from "../../../common/assetObjects";

const ProfileCard = props => {
    const showBackground = ![USER_ROLE.COMPANY_ADMIN, USER_ROLE.COMPANY_USER].includes(props.userRole);

    return (
        <div style={{background: createGradient(adjustColorBrightness(props.color, 90), 40)}}
             className={classes.ProfileCard}>
            <div style={{backgroundImage: showBackground ? `url(${CardBackground})` : undefined}}>
                {props.hidePreviewButton ? null : (
                    <div
                        className={classes.PreviewButton}
                        onClick={props.showConfirmation}
                    >
                        <FaUser size={12} color="#C9C9C9"/>
                        <p>Preview Profile</p>
                        <FiChevronRight size={14} color="#C9C9C9"/>
                    </div>
                )}
                <div className={classes.ProfileContainer}>
                    <div style={{color: props.color}} className={classes.ProfileInfoContainer}>
                        <h2>
                            {props.fullName?.trim() === '' ? 'Full Name' : props.fullName}
                        </h2>
                        <h5>{props.title?.trim() === '' ? 'Title' : props.title}</h5>
                        <h6>
                            {props.company?.trim() === '' ? 'Company' : props.company}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
